import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import ProductBrand from './ProductBrand'
import ProductCategory from './ProductCategory'
import { GlobalContext } from '../../appContext/GlobalContext'
import ProductReviews from '../ProductReviews/ProductReviews'

const Product = () => {
  const navigate = useNavigate()
  const { setShowHeader } = useContext(ProductBusketContext)
  const { productId, loading, autoOrder } = useContext(GlobalContext)

  const [isDetailsVisible, setIsDetailsVisible] = useState(false)

  const handleOpenDetails = () => setIsDetailsVisible(true)
  const handleCloseDetails = () => setIsDetailsVisible(false)
  const [orderAcceptanceStatus, setOrderAcceptanceStatus] = useState(true)

  const getOrderAcceptanceStatus = (status) => {
    setOrderAcceptanceStatus(status);
  }
  
  useEffect(() => {
    setShowHeader(true)
  }, [setShowHeader])

  useEffect(() => {
    window.localStorage.removeItem('thankyou_code')
    if (!loading && !productId) {
      navigate('/wrongGeneral')
    }
  }, [loading, navigate, productId])

  return !loading ? (
    <div className="p-[20px]">
      {isDetailsVisible ? (
        <ProductReviews onGoBack={handleCloseDetails} />
      ) : (
        <>
          <ProductBrand
            onReviewsClick={handleOpenDetails}
            auto_order={autoOrder}
            orderAcceptanceStatus={orderAcceptanceStatus}
          />
          <ProductCategory setOrderAcceptanceStatus={getOrderAcceptanceStatus} restId={productId} />
        </>
      )}
    </div>
  ) : null
}

export default Product
