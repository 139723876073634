import React, { createContext, useCallback, useEffect, useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import ReactLoading from 'react-loading'
import BucketsBox from './BusketsBox'
import ProductDialog from '../../components/ProductDialog/ProductDialog'
import { useContext } from 'react'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { GlobalContext } from '../../appContext/GlobalContext'
import { Button } from '../../common'
import Seo from '../../components/SEO/Seo'
import useViewportWidth from '../../hooks/useViewportWidth'
import { toast } from 'react-hot-toast'
import useProductsSync from '../../hooks/useProductsSync'
import { useCategoryImage } from './useCategoryImage'
import { scrollToTop } from '../../utils/scrollToTop'
import { BackIcon } from '../../components/icon'
import { Categories } from './Categories'
import { FaArrowRight } from 'react-icons/fa'

const dots = Array.from({ length: 10 }, (_, i) => i + 1)
const MAX_LENGTH = 150

const PRODUCT_IMAGE_KEYWORDS = [
  'alcohol',
  'drinks',
  'soft drinks',
  'coffee',
  'cafea',
  'latte',
  'cappucino',
  'beef',
  'vita',
  'sandwich',
  'sandvici',
  'panini',
  'soup',
  'brunch',
  'grill',
  'donut',
  'gogoasa',
  'desert',
  'ramen',
  'sushi',
  'japonez',
  'chinezesc',
  'supa',
  'ciorba',
  'bors',
  'peste',
  'fructe de mare',
  'pizza',
  'burger',
  'burge',
  'chicken',
  'pork',
  'porc',
  'pui',
  'gaina',
  'pasta',
  'salad',
  'salata',
  'vegan',
  'mexican',
  'tacos',
  'inghetata',
  'cigarettes',
  'beer',
  'tea',
  'cocktail',
  'wine',
  'cold drinks',
  'merchandise',
  'sodas',
  'suc',
  'limonada',
  'apă',
  'cuban',
  'babychino',
  'ceai',
  'tigarete',
  'prajituri',
  'vin',
  'prosecco',
  'ambalaj',
  'limonad',
  'bere',
  'espresso',
  'cheesecake',
  'milkshake',
  'narghilea',
  'kombucha',
  'superalimente',
  'sirop',
  'draugh',
  'shotter',
]

// For keywords that have same image
const COMMON_PRODUCT_IMAGE_MAP = {
  milkshake: 'milkshake',
  ramen: 'ramen',
  chinezesc: 'ramen',
  japonez: 'ramen',
  sushi: 'ramen',
  soup: 'soup',
  supa: 'soup',
  ciorba: 'soup',
  bors: 'soup',
  burger: 'burger',
  burge: 'burger',
  donut: 'donut',
  gogoasa: 'donut',
  desert: 'cake',
  prajituri: 'cake',
  salad: 'salad',
  salata: 'salad',
  coffee: 'coffee',
  cafea: 'coffee',
  latte: 'coffee',
  cappucino: 'coffee',
  chicken: 'chicken',
  pui: 'chicken',
  gaina: 'chicken',
  drinks: 'drinks',
  alcohol: 'drinks',
  'soft drinks': 'drinks',
  pork: 'pork',
  porc: 'pork',
  mexican: 'mexican',
  tacos: 'mexican',
  peste: 'peste',
  'fructe de mare': 'peste',
  beef: 'beef',
  vita: 'beef',
  sandwich: 'sandwich',
  sandvici: 'sandwich',
  panini: 'sandwich',
  cigarettes: 'cigarettes',
  tigarete: 'cigarettes',
  beer: 'beer',
  draugh: 'beer',
  bere: 'beer',
  tea: 'tea',
  kombucha: 'tea',
  cocktail: 'cocktail',
  wine: 'wine',
  vin: 'wine',
  prosecco: 'wine',
  ambalaj: 'togo',
  'cold drinks': 'cold drinks',
  merchandise: 'merchandise',
  sodas: 'drinks',
  limonad: 'drinks',
  sirop: 'drinks',
  suc: 'drinks',
  shotter: 'shot',
  apă: 'drinks',
  superalimente: 'drinks',
  'cuban latte': 'cocktail',
  babychino: 'tea',
  ceai: 'tea',
  espresso: 'coffee',
  cheesecake: 'cheesecake',
  narghilea: 'shisha',
}

const PRODUCT_FALLBACK_BASE_URL = '/assets/images/product-fallback'
export const PRODUCT_FALLBACK_IMAGE = `${PRODUCT_FALLBACK_BASE_URL}/default.svg`

export const settings_bucket = {
  dots: true,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 5,
  arrows: false,
  customPaging: (i) => {
    return <button>{dots[i]}</button>
  },
  appendDots: (dots) => {
    return <ul>{dots.slice(0, 6)}</ul>
  },
  responsive: [
    {
      breakpoint: 1350,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        dots: false,
        slidesToShow: 2.5,
        slidesToScroll: 1.5,
        initialSlide: 1.5,
        infinite: true,
      },
    },
  ],
}

// const SubCategory = ({ category, handleSelectCategory }) => {
const SubCategory = ({ category, handleSelectCategory, isEN }) => {
  const imageUrl = useCategoryImage(category)
  return (
    <div
      className="w-full cursor-pointer border border-gray-200 bg-white rounded-[10px] shadow p-[10px] flex flex-col justify-center mt-[5px]"
      onClick={() => {
        scrollToTop(() => handleSelectCategory(category))
      }}
    >
      <div className="flex">
        <div className="flex-1 flex flex-col justify-between h-full">
          <h3
            className="font-bold text-[1.3rem] pr-[10px]"
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: category.Description ? '1' : '3',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {/* {category.Name} */}
            {isEN ? category.NameEN : category.Name}
          </h3>
          <p
            className="text-[0.85rem] pr-[10px] text-ellipsis"
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '3',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {category.Description?.replace(/<[^>]+>/g, '')}
          </p>
        </div>
        {imageUrl && (
          <div className="relative w-[80px] h-[80px]">
            <img
              src={imageUrl}
              className="absolute top-0 left-0 w-full h-full object-cover rounded-[10px]"
            />
          </div>
        )}
      </div>
    </div>
  )
}

const findProductImageByKeyword = (productParam, exactMatch) => {
  const matchedKeyword = PRODUCT_IMAGE_KEYWORDS.find((keyword) =>
    exactMatch
      ? new RegExp(`\b${productParam?.toLowerCase?.()}\b`, 'i').test(keyword)
      : productParam?.toLowerCase?.()?.includes(keyword)
  )
  const keyword = COMMON_PRODUCT_IMAGE_MAP[matchedKeyword] || matchedKeyword

  return keyword && `${PRODUCT_FALLBACK_BASE_URL}/${keyword}.svg`
}

export const getProductFallbackImage = (productName, categoryName) => {
  return (
    findProductImageByKeyword(productName, true) ||
    findProductImageByKeyword(categoryName, false) ||
    PRODUCT_FALLBACK_IMAGE
  )
}

// const mapMenuItems = (menuItems, parentCategory, handleSelectCategory) => {
const mapMenuItems = (
  menuItems,
  parentCategory,
  handleSelectCategory,
  isEN
) => {
  let includeSubCategory = false
  const itemCategories = menuItems?.filter(
    (menuItem) =>
      menuItem.IsCategory &&
      !menuItem?.Items.some((x) => x.IsCategory) &&
      menuItem?.Items.length > 0
  )

  let subCategories = menuItems?.filter(
    (menuItem) =>
      menuItem.IsCategory &&
      menuItem?.Items.some((x) => x.IsCategory) &&
      menuItem?.Items.length > 0
  )

  if (subCategories && subCategories.length > 0) {
    includeSubCategory = true
    subCategories = [...subCategories, ...itemCategories]
  }

  const items = menuItems?.filter((menuItem) => !menuItem.IsCategory)
  // const categoryName = parentCategory ? parentCategory.Name : ' '
  const categoryName = parentCategory
    ? isEN
      ? parentCategory.NameEN
      : parentCategory.Name
    : ' '
  const catId = parentCategory ? parentCategory.ID : '#'
  return (
    <>
      {includeSubCategory ? (
        subCategories &&
        subCategories.map((subCategory, idx) => (
          <SubCategory
            key={idx}
            category={subCategory}
            handleSelectCategory={handleSelectCategory}
            isEN={isEN}
          />
        ))
      ) : (
        <>
          <h2 className="sm:text-[24px] text-[20px] font-semibold" id={catId}>
            {categoryName}
          </h2>
          {itemCategories &&
            itemCategories.length > 0 &&
            (itemCategories.length > 2 ? (
              <Slider {...settings_bucket} className="mb-[20px]">
                {itemCategories.map((category, idx) => (
                  <ProductCategoryItem
                    category={category}
                    key={`${idx}_main`}
                    categoryId={category.ID}
                    categoryName={isEN ? category.NameEN : category.Name}
                    // categoryName={category.Name}
                  />
                ))}
              </Slider>
            ) : (
              <div className="flex items-center justify-center">
                {itemCategories.map((category, idx) => (
                  <ProductCategoryItem
                    category={category}
                    className="w-[150px] h-[150px]"
                    key={`${idx}_main`}
                    categoryId={category.ID}
                    categoryName={isEN ? category.NameEN : category.Name}
                    // categoryName={category.Name}
                  />
                ))}
              </div>
            ))}
          {items && mapItems(items, parentCategory)}
          {itemCategories &&
            itemCategories.map((category, idx) => (
              <React.Fragment key={idx}>
                {/* {mapMenuItems(category.Items, category)} */}
                {mapMenuItems(category.Items, category, () => {}, isEN)}
              </React.Fragment>
            ))}
        </>
      )}
    </>
  )
}

const mapItems = (items, parentCategory) => {
  return (
    <React.Fragment key={parentCategory ? parentCategory.ID : '#'}>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-[10px]">
        {items.map((x) => mapItem(x))}
      </div>
    </React.Fragment>
  )
}

const mapItem = (product) => {
  return <BucketsBox key={product.ID} product={product} unit={'RON'} />
}

const ProductCategoryItem = ({
  className = '',
  category,
  categoryId,
  categoryName,
  description,
}) => {
  const imgSrc = useCategoryImage(category)
  const handleGotoCategory = () => {
    const element = document.getElementById(categoryId)
    element.scrollIntoView()
  }
  return (
    <div
      className={`bg-white rounded-[10px] border border-gray-100 shadow-md mb-[5px] mx-[2px] relative ${
        className ? className : 'aspect-w-1 aspect-h-1'
      }`}
      onClick={handleGotoCategory}
    >
      <div className="absolute inset-0 pt-[5px] px-[5px]">
        <div className="relative w-full h-[calc(100%_-_40px)]">
          {imgSrc && (
            <img
              src={imgSrc}
              alt="product image kfc"
              className="absolute top-0 left-0 w-full h-full object-cover rounded-[5px]"
            />
          )}
        </div>
        <div className="h-[40px] w-full flex items-center flex-col justify-center">
          <h3
            className="text-center font-medium text-[12px] sm:text-[14px]"
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {categoryName}
          </h3>
        </div>
      </div>
    </div>
  )
}

export const ProductContext = createContext()

const ProductCategory = ({ restId, setOrderAcceptanceStatus }) => {
  const [isLocationsLoading, setIsLocationsLoading] = useState(true)
  const viewportWidth = useViewportWidth()

  const {
    data,
    setData,
    product,
    setProduct,
    showDialog,
    setShowDialog,
    setIsItemUnavailableData,
    isItemUnavailableData,
    autoOrder,
    setAutoOrder,
  } = useContext(ProductBusketContext)
  const { colorCode, locationName, isEN } = useContext(GlobalContext)

  // const { colorCode, locationName } = useContext(GlobalContext)
  const [currentCategory, setCurrentCategory] = useState({})
  const [customerAvailabilityStatus, setCustomerAvailabilityStatus] =
    useState(true)

  const [currentLocationProducts, setCurrentLocationProducts] = useState([])
  const [showCategories, setShowCategories] = useState(false)

  const onProductComplete = useCallback(
    (data) => {
      setData(data?.Items)
      setCurrentCategory(data)
    },
    [setData]
  )
  const { isLoading: isProductsLoading, orderAcceptanceStatus } =
    useProductsSync(restId, onProductComplete)
  const isLoading = isProductsLoading || isLocationsLoading

  const fetchOrderAcceptanceStatus = (clientId) => {
    fetch(`${process.env.REACT_APP_API_URL}/startAcceptingOrders/${clientId}`)
      .then((res) => res.json())
      .then((data) => {
        const locations = data?.locations[0]
        if (locations) {
          setOrderAcceptanceStatus(locations.AcceptOrders)
          setCustomerAvailabilityStatus(locations.AcceptOrders)
        }
      })
  }

  useEffect(() => {

    if (restId) {
      setIsLocationsLoading(true)
      fetch(
        `${process.env.REACT_APP_API_URL}/partners/locations/location_products/${restId}`
      )
        .then((res) => res.json())
        .then((data) => {
          setCurrentLocationProducts(data?.locations)
          setIsLocationsLoading(false)
        })
        .catch((error) => {
          console.log(error)
          toast.error(
            'An error has occurred while loading the restaurant products'
          )
        })
    }
  }, [restId, setCurrentLocationProducts, setIsLocationsLoading])

  useEffect(() => {
    if (currentCategory?.ClientID) {
      setIsLocationsLoading(true)
      fetch(
        `${process.env.REACT_APP_API_URL}/setItemUnavailable/${currentCategory?.ClientID}`
      )
        .then((res) => res.json())
        .then((data) => {
          const sortFalseFirst = data?.locations
            ? data?.locations?.sort(
                (a, b) => Number(a.Available) - Number(b.Available)
              )
            : []
          setIsItemUnavailableData(sortFalseFirst)
          setIsLocationsLoading(false)
        })
      fetchOrderAcceptanceStatus(currentCategory.ClientID)
    }





  }, [currentCategory, setIsItemUnavailableData])

  useEffect(() => {
    if (viewportWidth < 500) settings_bucket.responsive[3].settings.dots = false
  }, [viewportWidth])

  const handleSelectCategory = (category) => {
    setCurrentCategory(category)
  }

  const getProduct = (products, productId) => {
    const product = products.find((product) => product.ProductId === productId)
    return product ? product.total_calculated : {}
  }

  const findCategoryById = (root, id) => {
    for (let i = 0; i < root.length; i++) {
      if (root[i].ID === id) return root[i]
      else if (root[i].IsCategory) {
        const result = findCategoryById(root[i].Items, id)
        if (result) return result
      }
    }
    return null
  }

  const handleBackCategory = () => {
    const parentId = currentCategory.ParentID
    const parentCategory = findCategoryById(data, parentId)
    if (parentCategory) {
      setCurrentCategory(parentCategory)
    } else {
      setCurrentCategory({
        Items: data,
      })
    }
  }

  const handleOpenCategories = () => {
    setShowCategories(true)
  }

  const handleCloseCategories = () => {
    setShowCategories(false)
  }

  return (
    <div>
      <Seo name={locationName} />
      <ProductContext.Provider
        value={{
          setProduct,
          setShowDialog,
          setIsItemUnavailableData,
          isItemUnavailableData,
          autoOrder,
          setAutoOrder,
        }}
      >
        {isLoading ? (
          <div className="flex flex-col justify-center items-center font-bold p-[30px]">
            <ReactLoading type="spinningBubbles" color={colorCode} />
            Loading
          </div>
        ) : (
          <>
            {currentCategory.Name ? (
              <Button
                text="Back"
                variant="icon"
                icon={<BackIcon />}
                onClick={handleBackCategory}
                colorCode={colorCode}
                style={{ backgroundColor: 'white', borderColor: 'white' }}
                gapBtwnTextAndIcon="gap-[2px]"
                className="py-[0px] px-[0px]"
              />
            ) : (
              <div className="flex justify-end w-full pt-3 pb-2">
                <button
                  style={{ color: colorCode }}
                  className="flex justify-center items-center gap-1"
                  onClick={handleOpenCategories}
                >
                  <p className="whitespace-nowrap">See All</p>
                  <FaArrowRight />
                </button>
              </div>
            )}
            {mapMenuItems(
              currentCategory.Items,
              currentCategory.Name,
              handleSelectCategory,
              isEN
              // handleSelectCategory
            )}
          </>
        )}
        {showCategories && (
          <Categories
            handleClose={handleCloseCategories}
            data={currentCategory.Items}
            selectCategory={(data, id) => {
              handleSelectCategory(data)
              setShowCategories(false)
              if (id) {
                setTimeout(() => {
                  const element = document.getElementById(id)
                  element.scrollIntoView()
                  console.log(id, element)
                }, 100)
              }
            }}
          />
        )}
        {product && (
          <ProductDialog
            customerAvailabilityStatus={customerAvailabilityStatus}
            show={showDialog}
            setShow={setShowDialog}
            product={product}
            nutritionalValues={getProduct(currentLocationProducts, product.ID)}
          />
        )}
      </ProductContext.Provider>
    </div>
  )
}

export default ProductCategory
