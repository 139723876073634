import React, { createContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading'
import { toast } from 'react-hot-toast'

export const GlobalContext = createContext()

const GlobalLayout = ({ children }) => {
    const url = new URL(window.location)
    let paramUuid;
    let productId;
    const { state } = useLocation();

    if (url.pathname.indexOf('/product/') > -1) {
        paramUuid = url.searchParams.toString().slice(0, -1)
        productId = url.pathname.replace('/product/', '')
        window.localStorage.setItem('productId', JSON.stringify(productId))
        window.localStorage.setItem('uuid', JSON.stringify(paramUuid))
    } else if (url.pathname.indexOf('/food-ordering') > -1) {
        let local_uuid = window.localStorage.getItem('uuid');
        if (!local_uuid) {
            toast.error('An error has occurred while processing the order. Please try again!')
            console.error('UUID is missing. Cannot proceed with the order.');
        }
        paramUuid = JSON.parse(local_uuid)
        const { location } = state;

        productId = location;
        window.localStorage.setItem('productId', JSON.stringify(productId))
        window.localStorage.setItem('uuid', JSON.stringify(paramUuid))
    }
    else if (url.pathname.indexOf('/empty-cart') > -1 || url.pathname.indexOf('/wrong') > -1) {
        let local_uuid = window.localStorage.getItem('uuid');
        if (!local_uuid) {
            toast.error('An error has occurred while processing the order. Please try again!')
            console.error('UUID is missing. Cannot proceed with the order.');
        }
        paramUuid = JSON.parse(local_uuid)
        productId = JSON.parse(window.localStorage.getItem('productId'))
        window.localStorage.setItem('productId', JSON.stringify(productId))
        window.localStorage.setItem('uuid', JSON.stringify(paramUuid))
    }

    const [loading, setLoading] = useState(true)
    const [locationName, setLocationName] = useState('')
    const [location, setLocation] = useState()
    const [colorCode, setColorCode] = useState('#FFB800')
    const [buttonTextColorCode, setButtonTextColorCode] = useState('#FFFFFF')
    const [stripeConnectedAccount, setStripeConnectedAccount] = useState('')
    const [onlinePayment, setOnlinePayment] = useState(true)
    const [cashPayment, setCashPayment] = useState(true)
    const [renderCheckout, setRenderCheckout] = useState(false)
    const [isProductLayout, setIsProductLayout] = useState(false)
    const [uuid, setUuid] = useState()
    const [selectedLanguage, setSelectedLanguage] = useState()

    const [isOpen, setIsOpen] = useState(false)
    const [listPickupAddress, setListPickupAddress] = useState([])
    const [method, setMethod] = useState('cash')
    const [openCloseHour, setOpenCloseHour] = useState({ openHour: '', closeHour: '' })

    const navigate = useNavigate()

    const [autoOrder, setAutoOrder] = useState(true)

    useEffect(() => {
        setLoading(true)
        if (productId) {
            if (paramUuid)
                setUuid(paramUuid)
            fetch(`${process.env.REACT_APP_API_URL}/partners/locations/${productId}`)
                .then((res) => res.json())
                .then((data) => {
                    setLocation(data?.locations[0])
                    setAutoOrder(data.locations[0]?.autoOrders)
                    setMethod(
                        data?.locations[0].onlinePayment === true
                            ? 'online'
                            : data?.locations[0].cash_orders === true
                                ? 'cash'
                                : 'online'
                    )
                    setColorCode(data?.locations[0].color_code)
                    window.localStorage.setItem(
                        'colorCode',
                        JSON.stringify(data?.locations[0].color_code) // for scrollToTop
                    )
                    setButtonTextColorCode(data?.locations[0].button_text_color_code ?? '#FFFFFF')
                    window.localStorage.setItem(
                        'buttonTextColorCode',
                        JSON.stringify(data?.locations[0].button_text_color_code ?? '#FFFFFF')
                    )
                    setOnlinePayment(data?.locations[0].onlinePayment ?? true)
                    setCashPayment(data?.locations[0].cash_orders ?? true)
                    setLocationName(data?.locations[0].name)
                    setStripeConnectedAccount(data?.locations[0].stripeAccount ?? '')
                    setRenderCheckout(true)
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                    console.log(error)
                    toast.error('An error has occurred while loading the restaurant')
                })
        }
        else if (productId === undefined) {
            setLoading(false)
        }
        else {
            setLoading(false)
            navigate('/wrongGeneral')
        }
    }, [navigate, paramUuid, productId, setCashPayment, setColorCode, setButtonTextColorCode, setLoading, setLocation, setLocationName, setMethod, setOnlinePayment, setRenderCheckout, setStripeConnectedAccount])

    return (
        <GlobalContext.Provider
            value={{
                method,
                setMethod,
                stripeConnectedAccount,
                setStripeConnectedAccount,
                locationName,
                setLocationName,
                colorCode,
                setColorCode,
                buttonTextColorCode,
                setButtonTextColorCode,
                onlinePayment,
                setOnlinePayment,
                cashPayment,
                setCashPayment,
                renderCheckout,
                setRenderCheckout,
                isProductLayout,
                setIsProductLayout,
                location,
                setLocation,
                productId,
                uuid,
                setUuid,
                loading,
                setLoading,
                selectedLanguage,
                setSelectedLanguage,
                isOpen,
                setIsOpen,
                autoOrder,
                listPickupAddress,
                setListPickupAddress,
                openCloseHour,
                setOpenCloseHour
            }}
        >
            {loading ? (
                <div className="flex flex-col justify-center items-center font-bold p-[30px]">
                    <ReactLoading type="spinningBubbles" color={colorCode} />
                    Loading
                </div>
            ) : children}
        </GlobalContext.Provider>
    )
}

export default GlobalLayout
 