import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const useProductsSync = (id, onComplete) => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (id) {
      setIsLoading(true)
      try {
        fetch(`${process.env.REACT_APP_API_URL}/syncProducts/${id}`)
          .then((res) => res.json())
          .then((data) => {
            const dataWithCategories = {
              ...data,
              Items: addCategoryToItems(data.Items),
            }
            onComplete?.(dataWithCategories)
            setIsLoading(false)
          }).catch((error)=>{
            console.log("error..",error); 
            navigate('/wrongGeneral') 

          })
      
      } catch (error) {
        console.log("error..",error); 
        navigate('/wrongGeneral') 
      }
    }
  }, [onComplete, id])

  return { isLoading }
}

const addCategoryToItems = (products) => {
  products.forEach((product) => {
    function addCategory(items, categoryName) {
      items.forEach((item) => {
        item.Category = categoryName
        if (item.items && item.items.length > 0) {
          addCategory(item.items, categoryName)
        }
      })
    }

    addCategory(product.Items, product.Name)
  })

  return products
}

export default useProductsSync
