import React, { useContext, useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import { Button, TextArea, TextInput } from '../../common'
import { GlobalContext } from '../../appContext/GlobalContext'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Feedback = ({ setBusket, setTips, colorCode }) => {
  const { uuid, productId, location } = useContext(GlobalContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [feedbackText, setFeedbackText] = useState('')
  const [email, setEmail] = useState('')
  const [rating, setRating] = useState(5)

  const handleRating = (rate) => {
    setRating(rate)
  }

  const handleChange = (e) => {
    setFeedbackText(e.target.value)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmitFeedback = () => {
    let formattedDate = new Date().toISOString().slice(0, 10)
    fetch(`${process.env.REACT_APP_API_URL}/partners/locations/rating_reviews`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reviewerName: email,
        reviewDate: formattedDate,
        reviewRating: rating,
        reviewText: feedbackText,
        LocationId: productId,
      }),
    })
      .then((res) => {
        toast.success('Successfully sent', {
          position: 'bottom-right',
        })
      })
      .catch((err) => {
        toast.error('Error occurred!', {
          position: 'bottom-right',
        })
      })
      .finally(() => {
        let busket = JSON.parse(window.localStorage.getItem('busket')) || [];
        const filteredBusket = busket.filter(item => item.locationId !== location.LocationId);
        window.localStorage.setItem('busket', JSON.stringify(filteredBusket));
        window.localStorage.removeItem('tips');
        setBusket(filteredBusket);
        setTips(0);
          navigate(`/product/${productId}?${uuid}`)
      })
  }

  return (
    <div className="w-full">
      <div className="mt-[21px] w-full text-center">
        <h2 className="text-[20px] font-semibold">{t('feedback.title')}</h2>
        <div className="text-[16px] font-normal my-[8px]">
          {t('feedback.title2')}
        </div>
        <Rating
          fillColor={colorCode}
          onClick={handleRating}
          initialValue={rating}
          className="flex flex-row"
          SVGstyle={{ display: 'inline' }}
          size={45}
          allowFraction
        />
        <div className="text-[18px] font-medium mt-[20px] text-left">
          {t('feedback.feedbackTitle')}
        </div>
        <TextArea className="w-full mt-[6px]" onChange={handleChange} />
        <div className="text-[18px] font-medium mt-[20px] text-left">
          {t('feedback.emailTitle')}
        </div>
        <TextInput
          className="w-full mt-[6px]"
          type="email"
          onChange={handleEmailChange}
        />
        <div className="text-[14px] font-normal mt-[6px] text-left text-[#9C9C9C]">
          {t('feedback.emailInfoText')}
        </div>
        <div className="flex justify-center mt-[34px]">
          <Button
            colorCode={colorCode}
            text={t('feedback.buttonTitle')}
            variant="outline"
            onClick={handleSubmitFeedback}
            className="text-[16px] w-full font-medium py-3"
          />
        </div>
      </div>
    </div>
  )
}

export default Feedback
