import {useEffect, useState} from "react";
import { Star } from '../../../components/Star'
import { formatNumber } from '../../../utils/number'
import { ReviewItem } from './ReviewItem'

const PAGINATION_LIMIT = 10;

export const ReviewsList = ({ className, totalRating, avgRating, reviews }) => {
  const sortedReviews = [...reviews].sort(
      (a, b) => new Date(b.reviewDate) - new Date(a.reviewDate)
  );

  const [showMore,setShowMore] = useState(true);
  const [visibleReviewCount, setVisibleReviewCount] = useState(PAGINATION_LIMIT)
  const [initialReview,setInitialReviews] = useState([]);

  useEffect(() => {
    setInitialReviews(sortedReviews.slice(0, visibleReviewCount));
    setShowMore(visibleReviewCount < sortedReviews.length);
  }, [reviews, visibleReviewCount]);

  const handleLoadMore = () => {
    setVisibleReviewCount(prevCount => prevCount + PAGINATION_LIMIT);
  };

  return (
    <div className={className}>
      <div className="flex justify-between items-center">
        <h3 className="font-semibold text-xl">Recenzii</h3>
        <div className="flex items-center gap-2">
          <span className="font-semibold text-xl">
            {formatNumber(avgRating)}
          </span>
          <Star isSelected />
          <span className="text-[#3C3A34] text-sm">({totalRating})</span>
        </div>
      </div>
        <div className="flex flex-col gap-2.5 pt-4">
          {initialReview.map((item) => {
              return <ReviewItem key={item.ReviewId} item={item}/>
          })}
          {showMore && <button className="mt-5" onClick={handleLoadMore}>Load More</button>}
        </div>
    </div>
  )
}
