import { useContext, useState } from 'react'
import { toast } from 'react-hot-toast'
import { GlobalContext } from '../../appContext/GlobalContext'
import { useLocationRating } from '../../hooks/useLocationRating'
import { ReviewsHeader } from './components/ReviewsHeader'
import { LocationDetails } from './components/LocationDetails'
import { ReviewsList } from './components/ReviewsList'
import { AddReviewModal } from './components/AddReviewModal'
import { addReview } from '../../api/addReview'
import Button from '../../common/Button'
import { getNewReviewDate } from '../../utils/date'

//TODO IMPLEMENT PAGINATION
// const REVIEW_PER_PAGE = 5

const ProductReviews = ({ onGoBack }) => {
  const { location } = useContext(GlobalContext)
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false)
  const [isAddingReview, setIsAddingReview] = useState(false)
  const productReviews = useLocationRating(location?.LocationId)

  const onAddReview = async ({ rating, comment, email }) => {
    try {
      setIsAddingReview(true)
      const reviewBody = {
        reviewerName: 'UNKNOWN',
        reviewDate: getNewReviewDate(),
        reviewEmail: email,
        reviewRating: rating,
        reviewText: comment,
        LocationId: location.LocationId,
      }

      await addReview(reviewBody)
      toast.success('Thanks for you review!')
      onGoBack()
    } catch {
      toast.error('Failed to add review')
      setIsAddingReview(false)
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <ReviewsHeader onBackButtonClick={onGoBack} />
      <LocationDetails location={location} className="pt-4" />
      <ReviewsList
        reviews={
          Array.isArray(productReviews?.reviews) ? productReviews.reviews : []
        }
        totalRating={productReviews?.total_ratings || 0}
        avgRating={productReviews?.average_rating || 0}
      />
      {/* <Pagination
        onPageChange={setActivePage}
        activePage={activePage}
        className="mt-2"
        total={50}
        pageSize={REVIEW_PER_PAGE}
      /> */}
      <Button
        onClick={() => setIsReviewModalOpen(true)}
        text="Scrie-ne"
        className="w-full mt-8"
        colorCode={location?.color_code}
      />
      <AddReviewModal
        isOpen={isReviewModalOpen}
        onClose={() => setIsReviewModalOpen(false)}
        onSubmit={onAddReview}
        isSubmitting={isAddingReview}
        colorCode={location?.color_code}
      />
    </div>
  )
}

export default ProductReviews
