import { BackIcon } from '../../../components/icon'
export const ReviewsHeader = ({ onBackButtonClick }) => {
  return (
    <div className="h-[80px] fixed p-[5px] w-full z-[1] top-0 left-0 nested-route-header">
      <div className="flex items-center font-semibold text-xl flex items-center backdrop-blur-md shadow-lg rounded-[10px] py-[15px] bg-[#ffffff5e]">
        <button
          onClick={onBackButtonClick}
          className="h-[44px] w-[44px] z-[999] cursor-pointer"
        >
          <BackIcon />
        </button>
        <h1 className=" absolute w-full text-center">Detalii</h1>
      </div>
    </div>
  )
}
