import OrderSummary from '../FoodOrder/OrderSummary'
import PaymentSelector from './PaymentSelector'
import React, { useContext, useEffect, useState } from 'react'
import Tips from './Tips'
import { GlobalContext } from '../../appContext/GlobalContext'
import Seo from '../../components/SEO/Seo'
import DelivPickup from './DelivPickup'
import toast from 'react-hot-toast'
import { ProductBusketContext } from '../../layouts/ProductLayout'

const FinishOrder = () => {
  // const { t } = useTranslation()
  const { method, setMethod, colorCode, buttonTextColorCode, loading, location, setListPickupAddress, uuid } = useContext(GlobalContext)
  const { setDelivPickupType } = useContext(ProductBusketContext)
  const [selectedTip, setSelectedTip] = useState(0)
  const [delivOrPickup, setDelivOrPickup] = useState('')
  const handleChangeSelectTip = (tip) => {
    setSelectedTip(tip)
  }

  const fetchLocationsForPickup = async () => {
    try {
      let res = await fetch(`${process.env.REACT_APP_API_URL}/partners/locationsForPickup?ClientId=${location?.PartnerId}`)
      let data = await res.json()
      let tempData = []
      for (let i = 0; i < data.locations.length; i++) {
        tempData.push(data.locations[i].address)
      }
      setListPickupAddress(tempData)
    } catch (error) {
      console.error('Error fetching locations for pickup:', error)
      toast.error("Error fetching locations for pickup", {
        position: 'bottom-right',
      })
    }
  }

  useEffect(() => {
    if (location?.delivery && !uuid) {
      setDelivOrPickup('delivery')
    } else if (location?.pickup && !location?.delivery && !uuid) {
      setDelivOrPickup('pickup')
    }
    fetchLocationsForPickup()
  }, [])

  useEffect(() => {
    setDelivPickupType(delivOrPickup)
  }, [delivOrPickup])

  return (
    <div className="p-[10px]">
      <Seo name="Finish Order" />
      {!loading && <PaymentSelector method={method} setMethod={setMethod} colorCode={colorCode} />}
      {console.log(uuid)}
      {!loading &&
        (location?.pickup || location?.delivery) &&
        (!uuid || uuid === null || uuid === 'null' || uuid === undefined || uuid === "undefined") &&
        <DelivPickup
          colorCode={colorCode}
          delivOrPickup={delivOrPickup}
          setDelivOrPickup={setDelivOrPickup}
          isPickupAvail={location?.pickup}
          isDelivAvail={location?.delivery}
        />
      }

      {!loading && <Tips selectedTip={handleChangeSelectTip} colorCode={colorCode} buttonTextColorCode={buttonTextColorCode} />}
      {method === 'online' && (
        <div>
          {/*<h4 className="font-bold mt-[15px] ml-[10px]">*/}
          {/*  {t('product.phone_number')}*/}
          {/*</h4>*/}
          {/*<TextInput*/}
          {/*  name="phone"*/}
          {/*  placeholder={t('product.phone_number')}*/}
          {/*  value={phone}*/}
          {/*  onChange={handleChangePhone}*/}
          {/*/>*/}
          {/*<h4 className="font-bold mt-[15px] ml-[10px]">*/}
          {/*  {t('product.email')}*/}
          {/*</h4>*/}
          {/*<TextInput*/}
          {/*  name="email"*/}
          {/*  placeholder={t('product.email')}*/}
          {/*  value={email}*/}
          {/*  onChange={handleChangeEmail}*/}
          {/*/>*/}
        </div>
      )}
      {!loading && <OrderSummary delivOrPickup={delivOrPickup} final method={method} selectedTip={selectedTip} />}
    </div>
  )
}

export default FinishOrder
