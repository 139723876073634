const allergenImages = {
  Gluten: '/assets/images/product/Wheat.svg',
  Crustacee: '/assets/images/product/Crab.svg',
  Ouă: '/assets/images/product/Eggs.svg',
  Peste: '/assets/images/product/Fish.svg',
  Arahide: '/assets/images/product/Peanuts.svg',
  Soia: '/assets/images/product/Soya.svg',
  Lapte: '/assets/images/product/milk.svg',
  Țelină: '/assets/images/product/Celery.svg',
  Muștar: '/assets/images/product/Mustard.svg',
  Susan: '/assets/images/product/Sesame.svg',
  Sulfiti: '/assets/images/product/Sulphites.svg',
  Lupin: '/assets/images/product/Lupine.svg',
  Moluște: '/assets/images/product/Crab.svg',
}

export default allergenImages
