import React, { useEffect, useRef, useState } from 'react'
import { RxCross2 } from 'react-icons/rx'
import { useCategoryImage } from './useCategoryImage'

const SubCategories = (props) => {
  const { item, selectSubCategory } = props
  const imageUrl = useCategoryImage(item)
  return (
    item.IsCategory &&
    item.Items.length > 0 && (
      <div
        onClick={() => selectSubCategory(item.ID)}
        className="flex flex-col shadow border rounded-lg"
      >
        <img src={imageUrl} className="h-24 w-full object-cover rounded-lg" />
        <p className="p-2">{item.Name}</p>
      </div>
    )
  )
}

const SingleCategories = (props) => {
  const { item, selectCategory } = props
  const imageUrl = useCategoryImage(item)
  const checkSubCategories = (data) => {
    return data.map((e) => e.IsCategory).filter((e) => e === true).length > 0
  }
  const moveToSubCategories = (id) => {
    selectCategory(item, id)
  }

  const paragraphRef = useRef(null)
  const [numberOfLines, setNumberOfLines] = useState(0)

  useEffect(() => {
    if (paragraphRef.current) {
      const computedStyle = window.getComputedStyle(paragraphRef.current)
      const lineHeight = parseFloat(computedStyle.lineHeight)
      const paragraphHeight = paragraphRef.current.clientHeight
      const lines = Math.round(paragraphHeight / lineHeight)

      setNumberOfLines(lines)
    }
  }, [])

  return (
    <div className={'py-[4px] w-full'}>
      <div
        onClick={() => selectCategory(item)}
        className={
          'flex gap-2 shadow border p-2 rounded-lg ' +
          (numberOfLines > 3 ? 'h-32' : 'h-24')
        }
      >
        <div className="flex-1 flex items-center">
          <p className="text-lg" ref={paragraphRef} id="yourParagraphId">
            {item.Name}
          </p>
        </div>
        <div className="flex-1 w-full">
          <div
            style={{
              clipPath:
                'polygon( 94.005% 100%,5.359% 100%,5.359% 100%,5.323% 100%,5.287% 100%,5.252% 99.999%,5.216% 99.999%,5.181% 99.997%,5.146% 99.995%,5.111% 99.993%,5.076% 99.989%,5.041% 99.984%,5.006% 99.978%,5.006% 99.978%,3.84% 99.579%,2.799% 98.714%,1.899% 97.45%,1.154% 95.855%,0.582% 93.998%,0.196% 91.945%,0.013% 89.764%,0.048% 87.523%,0.317% 85.29%,0.834% 83.132%,24.736% 5.7%,24.736% 5.7%,25.088% 4.679%,25.486% 3.747%,25.925% 2.906%,26.402% 2.163%,26.913% 1.521%,27.452% 0.986%,28.017% 0.561%,28.603% 0.253%,29.206% 0.064%,29.821% 0%,94.005% 0%,94.005% 0%,94.977% 0.158%,95.899% 0.617%,96.759% 1.351%,97.545% 2.335%,98.243% 3.544%,98.843% 4.955%,99.33% 6.541%,99.694% 8.277%,99.921% 10.14%,100% 12.104%,100% 87.903%,100% 87.903%,99.922% 89.865%,99.694% 91.727%,99.331% 93.463%,98.844% 95.05%,98.245% 96.46%,97.546% 97.67%,96.761% 98.655%,95.9% 99.39%,94.978% 99.849%,94.005% 100.007% )',
            }}
            className="flex justify-center items-center h-full w-full"
          >
            <img
              src={imageUrl}
              alt=""
              className="h-full object-cover w-full scale-110"
            />
          </div>
        </div>
      </div>
      {checkSubCategories(item.Items) && (
        <div className="grid grid-cols-2 gap-3 py-3">
          {item.Items.map((e, i) => (
            <SubCategories
              key={i}
              item={e}
              selectSubCategory={(id) => moveToSubCategories(id)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export const Categories = (props) => {
  const { data, handleClose, selectCategory } = props

  return (
    <div className="fixed w-full h-full bg-[#878787c2] left-0 top-0 z-10 backdrop-blur-sm flex justify-center items-center">
      <div className="relative sm:w-[600px] w-[95%] bg-white rounded-lg shadow-lg sm:h-[90%] h-[95%] p-4 overflow-auto">
        <div className="flex justify-between items-center w-ful py-2">
          <p className="font-bold text-lg">Categories</p>
          <button
            className="bg-[#FFF] transition duration-300 ease-in-out hover:bg-gray-100 hover:shadow-lg rounded-full px-2"
            onClick={handleClose}
          >
            <RxCross2 style={{ color: '#000000' }} />
          </button>
        </div>
        {data &&
          data.map((e, i) => (
            <SingleCategories
              key={i}
              item={e}
              selectCategory={selectCategory}
            />
          ))}
      </div>
    </div>
  )
}
